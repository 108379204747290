// This is the template for a single sign
import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";
import { ArrowImage } from "../../images/icons";
const RotatingImg = ({ imageA, imageB, classes, index }) => {
  const first = (index + 1) % 1 === 0;
  const second = (index + 1 || (index <= 0 && index)) % 2 === 0;
  const third = (index + 1) % 3 === 0;

  const image = {
    hidden: { opacity: 1 },
    hover: { opacity: 0.5 },
  };
  const arrow = {
    hidden: { opacity: 0, y: -20 },
    hover: { opacity: 1, y: 0 },
    tapped: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      initial="hidden"
      whileHover="hover"
      whileTap="tapped"
      className={`card-container ${classes} h-full`}
    >
      <motion.div
        variants={arrow}
        className={
          "absolute top-0 z-[2] flex justify-center items-center w-full h-full"
        }
      >
        <ArrowImage classes={"h-[2rem]"} />
      </motion.div>
      <div
        id={"card"}
        style={{
          animationName: `${
            second
              ? "cardRotateX"
              : third
              ? "cardRotateY"
              : first && "cardRotateZ"
          }`,
          animationDuration: `${second ? "7s" : third ? "8s" : first && "9s"}`,
        }}
      >
        <motion.figure
          variants={image}
          className={`${second || third ? "front" : first && "frontZ"} `}
        >
          <GatsbyImage image={imageA} alt={""} />
        </motion.figure>
        <motion.figure
          variants={image}
          className={`${
            second ? "backX" : third ? "backY" : first && "backZ"
          } `}
        >
          <GatsbyImage image={imageB} alt={""} />
        </motion.figure>
      </div>
    </motion.div>
  );
};

export default RotatingImg;
