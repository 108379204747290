import React from "react";
import { Link } from "gatsby";
import { ArrowRegIcon } from "../../images/icons";
import { LinkIcon } from "../shared/links";
import {
  responsiveTextBasic,
  responsiveIconsBasic,
  stickyNav,
} from "../shared/styles";

export const ProjectsTypeNavbar = ({ project }) => {
  return (
    <div
      className={`flex mb-xl capitalize ${responsiveTextBasic} ${stickyNav} bg-yellow-50 z-[29]`}
    >
      {project ? (
        <div className="flex items-center mr-5">
          <Link className=" capitalize mr-5" to={`/project-types`}>
            <LinkIcon classes={responsiveTextBasic}>Project types</LinkIcon>
          </Link>
          <ArrowRegIcon classes={`${responsiveIconsBasic} h-[1rem]`} />
        </div>
      ) : (
        <div className={`${responsiveIconsBasic} `}>Project types:</div>
      )}
      <div className={`${responsiveIconsBasic} opacity-50`}>
        {project && project}
      </div>
    </div>
  );
};
