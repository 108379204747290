// This is the template for a single sign
import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/shared/layout";
import { Seo } from "../components/shared/seo";
import { BackBtn } from "../components/shared/backBtn";
import { ProjectsTypeNavbar } from "../components/projectsType/projectsTypeNavbar";
import { ProjectGridItem } from "../components/shared/projectGridItem";
import { gridProjectStyle } from "../utils/gridStyles";
// ------------> Sign (single) page <------------ //
const ProjectTypePageTemplate = (props) => {
  // --->  Data <--- //
  const { projectType, projects } = props.data.strapiProjectType;
  // const nextProjectType = props.pageContext.nextMediumSlug;
  // const prevSign = props.pageContext.previousSignSlug;
  const projectsAndType = [
    Object.assign(...projects, {
      projectType: { projectType: projectType },
    }),
  ];
  return (
    <Layout location={props.location}>
      <ProjectsTypeNavbar project={projectType} />
      <div className={`${gridProjectStyle}`}>
        {projectsAndType.map((project, index) => {
          return (
            <ProjectGridItem
              key={index}
              project={project}
              classes={""}
              isMedium={false}
              location={props.location}
            />
          );
        })}
      </div>

      {/* ---> Back <--- */}
      <BackBtn classes={"my-xl"} />
    </Layout>
  );
};

export const query = graphql`
  query ($projectType: String) {
    site {
      siteMetadata {
        title
      }
    }
    strapiProjectType(projectType: { eq: $projectType }) {
      projectType
      projects {
        title
        year
        heroImage {
          imageFile {
            id
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: NO_CHANGE
                  #width: 700
                  height: 300
                  quality: 100
                  #layout: FULL_WIDTH
                  transformOptions: { fit: COVER }
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
    }
  }
`;
export default ProjectTypePageTemplate;

// *---> SEO
export const Head = () => (
  <Seo
    title={"Mediums"}
    description={"IlCollection Artists"}
    keywords={"IlCollection Artists, Art"}
  />
);
