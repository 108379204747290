import * as React from "react";
import { getImage } from "gatsby-plugin-image";
import { LinkType, VrLink } from "./links";
import { Link } from "gatsby";
import slugify from "slugify";
import { motion, useInView, useAnimation } from "framer-motion";
import RotatingImg from "../projects/rotatingImg";
import { LinkIcon } from "./links";

export const ProjectGridItem = ({ project, slug, classes, index }) => {
  const { projectType, year, title } = project;

  const projectHerolImage = getImage(
    project.heroImage.imageFile.localFile.childImageSharp
  );

  // Animation inView
  // IN-VIEW SHOW
  const pageContainerRef = React.useRef(null);
  const isInView = useInView(pageContainerRef, { once: false, amount: 0.1 });
  const inViewControls = useAnimation();
  const inViewVariants = {
    visible: {
      opacity: 1,
      y: 0,
    },
    hidden: {
      opacity: 0,
      y: 5,
    },
  };
  React.useEffect(() => {
    if (isInView) {
      inViewControls.start("visible");
    } else if (!isInView) {
      inViewControls.start("hidden");
    }
  }, [inViewControls, isInView]);

  return (
    <div className={classes}>
      <div className={"relative h-full w-full "}>
        <Link className="" to={`/project/${slugify(title.toLowerCase())}`}>
          <div className="relative ">
            <motion.div
              ref={pageContainerRef}
              animate={inViewControls}
              initial={"hidden"}
              variants={inViewVariants}
              transition={{ type: "easeOut", duration: 1 }}
              className={"flex justify-center items-center"}
            >
              <RotatingImg
                imageA={projectHerolImage}
                imageB={projectHerolImage}
                classes={
                  // "xs:w-[300px] md:w-[400px] md:h-[400px] xs:h-[300px]"
                  "xs:max-w-[300px] md:max-w-[400px] md:h-[400px] xs:h-[300px] xs:w-[60%] lg:w-[100%]"
                }
                index={index}
              />
            </motion.div>
            {/* ------ VR LINK? ------  */}
            {/* {projectType &&
            projectType.projectType.toLowerCase() === "exhibition" ? (
              <div className="absolute top-0 w-full h-full flex justify-center items-center">
                <VrLink url={"http://www.cnn.com"} />
              </div>
            ) : null} */}
          </div>
        </Link>
        <LinkType
          slug={"project-type"}
          type={projectType.projectType}
          isMedium={false}
          classes={"mt-md"}
        />
        <Link className="" to={`/project/${slugify(title.toLowerCase())}`}>
          <div className="xs:text-md lg:text-lg mt-md">
            <LinkIcon> {title}</LinkIcon>
          </div>
          <div className="xs:text-sm lg:text-base mt-md">
            <LinkIcon>{year} </LinkIcon>
          </div>
        </Link>
      </div>
    </div>
  );
};
